import React, { useEffect } from "react";

import { withLocale } from "../contexts/locale.context";
import ItcAccordion from "./ItcAccordion";
import services_softdev from "../data/services_softdev.json";
import services_devops from "../data/services_devops.json";
import services_other from "../data/services_other.json";
import "./services.css";

function ServicesComponent({ lang }) {
  const softDevelopment = services_softdev;
  const devops = services_devops;
  const other = services_other;

  const accordions = [
    { id: "accordion", title: "servicesSoftDev", accordion: softDevelopment },
    { id: "accordion_devops", title: "servicesDEVOPS", accordion: devops },
    { id: "accordion_other", title: "servicesOther", accordion: other },
  ];

  useEffect(() => {
    async function Start() {
      const target_element = await document.getElementById("accordion");
      const target_element2 = await document.getElementById("accordion_devops");
      const target_element3 = await document.getElementById("accordion_other");
      new ItcAccordion(target_element, {
        alwaysOpen: false,
        duration: 500,
      });
      new ItcAccordion(target_element2, {
        alwaysOpen: false,
        duration: 500,
      });
      new ItcAccordion(target_element3, {
        alwaysOpen: false,
        duration: 500,
      });
    }

    Start();
  }, []);

  return (
    <div id="services" className="services-wrapper">
      <div>
        <div>
          <h1 className="header_text_gradient">{lang.handle("servicesHeader")}</h1>
        </div>

        {accordions.map((acc, index) => {
          return (
            <div key={acc.id + index} className="container_services">
              <div className="title">{lang.handle(acc.title)}</div>
              <div id={acc.id} className="accordion">
                {acc.accordion.map((item, index) => {
                  return (
                    <div key={index} className="accordion__item">
                      <div className="accordion__header">
                        <span>/ 0{index + 1} /</span>
                        <h2>{lang.handle(item.summary)}</h2>
                      </div>
                      <div className="accordion__body">
                        <div className="accordion__content">
                          {lang.handle(item.details)}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export const Services = withLocale(ServicesComponent);
