import React, { useState } from "react";

import { withLocale } from "../contexts/locale.context";
import Attachment from "../images/material-symbols_attachment.svg";
import "./your-project.css";
import Button from "./UI/Button";
import InputText from "./UI/InputText";
import axios from "axios";

function YourProjectComponent({ lang }) {
  const [firstName, setFirstName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [askDetails, setAskDetails] = useState("");
  const [files, setFiles] = useState([]);

 const handleFileChange = (e) => {
   const newFiles = Array.from(e.target.files);
   setFiles([...files, ...newFiles]);
 };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let text = `**Расскажите о своем проекте**\nИмя: ${firstName}\nТелефон: ${phone}\nПочта: ${email}\nДетали: ${askDetails}`;
    let formData = new FormData();
    formData.append('template', 'site');
    formData.append('text', text);
    if (files) {
      for (const element of files) {
        formData.append("files", element);
      }
    }

    try {
      const response = await axios.post('/api/v1/message', formData);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div id="your_project">
      <div>
        <div>
          <h1 className="header_text_gradient ">{lang.handle("askYourProject")}</h1>
        </div>
        <div style={{ height: 80 }}></div>
        <div className="container_your_project">
          <div>
            <div className="flash_container">
              <InputText
                id="firstName"
                label={lang.handle("firstName")}
                value={firstName}
                onChange={setFirstName}
                required={false}
                textLength={false}
                // width="524px"
                type="text"
              />
              <div className="yr_project_flash star1_yr_project_flash"></div>
            </div>
            <InputText
              id="phone"
              label={lang.handle("phone")}
              value={phone}
              onChange={setPhone}
              required={false}
              textLength={false}
              // width="524px"
              type="text"
            />
            <div className="flash_container">
              <InputText
                id="email"
                label={lang.handle("email")}
                value={email}
                onChange={setEmail}
                required={false}
                textLength={false}
                // width="524px"
                type="text"
              />
              <div className="yr_project_flash star2_yr_project_flash"></div>
            </div>
            <div className="flash_container">
              <InputText
                id="askDetails"
                label={lang.handle("askDetails")}
                value={askDetails}
                onChange={setAskDetails}
                required={false}
                textLength={true}
                maxLength="255"
                // width="524px"
                type="text"
              />
              <div className="yr_project_flash star3_yr_project_flash"></div>
            </div>

            <div style={{ height: 80 }}></div>

            <div>
              {files.length > 0 && (
                  <ul>
                    {files.map((file, index) => (
                        <li key={index}>{file.name}</li>
                    ))}
                  </ul>
              )}
            </div>
            <div className="attachment">
              <div>
                <label htmlFor="attachment">
                  <span>{lang.handle("attachFile")}</span>
                  <img src={Attachment} alt="" className="attachment-img" />
                </label>
                <input
                  type="file"
                  multiple
                  className="attachment-input"
                  id="attachment"
                  onChange={handleFileChange}
                />
              </div>
            </div>

            <div style={{ height: 72 }}></div>

            <div className="yr_project_btn_container">
              <div
                className="flash_container"
                style={{
                  width: 192,
                }}
              >
                <div>
                  <Button
                    style={{ width: 192 }}
                    onClick={handleSubmit}
                  >
                    {lang.handle("btnSend")}
                  </Button>
                </div>
                <div className="yr_project_flash btn_yr_project_flash"></div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ height: 158 }}></div>
      </div>
    </div>
  );
}

export const YourProject = withLocale(YourProjectComponent);
