import React, {useEffect, useState} from "react";

import { withLocale } from "../../contexts/locale.context";
import styles from "../../css/Modal.module.css";
import Close from "../../images/close_modal_white.svg";
import Attachment from "../../images/material-symbols_attachment.svg";
import background from "../../images/background-brief.png";
import InputText from "../UI/InputText";
import Button from "../UI/Button";
import brief_tasks from "../../data/brief_tasks.json";
import brief_duedates from "../../data/brief_duedates.json";
import brief_budget from "../../data/brief_budget.json";
import scroll from "../../images/scroll-down.svg";
import axios from "axios";

const ModalINeedComponent = ({
  setIsOpenINeed,
  setIsOpenModalConfirmation,
  setIsOpenModalError,
  lang,
}) => {
  const tasks = brief_tasks;
  const dueDates = brief_duedates;
  const budget = brief_budget;

  const [firstName, setFirstName] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [anyTask, setAnyTask] = useState("");
  const [allowMessengers, setAllowMessengers] = useState(false);
  const [files, setFiles] = useState([]);

  const [selectTask, setSelectTask] = useState([tasks[4]]);
  const [selectDueDates, setSelectDueDates] = useState(dueDates[1]);
  const [selectBudget, setSelectBudget] = useState(budget[1]);

  const tagsSelected = [selectTask, [selectDueDates], [selectBudget]];

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [step, setStep] = useState(1);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    console.log(newFiles);
    setFiles([...files, ...newFiles]);
    console.log(files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let tasks = [];
    if (selectTask) {
      selectTask.forEach((item, index) => {
        tasks.push(lang.handle(item));
      });
    }
    let dates = lang.handle(selectDueDates);
    let budget = lang.handle(selectBudget);
    let messengers = allowMessengers ? lang.handle("yes") :  lang.handle("no");
    let text = `<b>Бриф</b>\nЗадача: ${tasks.join(", ")}\nСвоя задача: ${anyTask}\nСроки: ${dates}\nБюджет: ${budget}\nИмя: ${firstName}\nКомпания: ${company}\nТелефон: ${phone}\nПочта: ${email}\nКомментарий: ${comment}\nМожно писать в мессенджеры: ${messengers}`;
    console.log(text);
    let formData = new FormData();
    formData.append('template', 'site');
    formData.append('text', text);
    if (files) {
      for (const element of files) {
        formData.append("files", element);
      }
    }
    return axios.post('/api/v1/message', formData)
        .then((responce) => {
      setIsOpenINeed(false);
      setIsOpenModalConfirmation(true);
    })
        .catch((error)=> {
      console.error(error);
      setIsOpenINeed(false);
      setIsOpenModalError(true);
    })
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const closeModal = () => {
    document.getAnimations().forEach((animation) => {
      animation.playbackRate = 1;
    });
    // document.body.style.overflow = "scroll";
    setIsOpenINeed(false);
  };

  const nextStep = () => {
    setStep(Math.min(3, step + 1));
  };
  const previousStep = () => {
    setStep(Math.max(1, step - 1));
  };

      return (
    <>
      <div className={styles.blurBG} onClick={closeModal} />
      <div className={styles.centered}>
        <div className={styles.modalINeed} style={{backgroundImage: `url(${background})`}}>
          {screenWidth >= 1200 ? (
              <div className={styles.modalHeader}>
                <h2 className={styles.modalHeader_text}>{lang.handle("modalINeedTitle")}</h2>
                <div className={styles.modalHeader_tags_img}>
                  <div className={styles.selectedTags}>
                    {tagsSelected.map((itemArr) => {
                      return itemArr.map((item, index) => {
                        return (
                            <div
                                key={item + index}
                                className={styles.selectedTag}
                                onClick={() => {
                                  if (selectTask.includes(item)) {
                                    if (selectTask.length < 2) return;
                                    setSelectTask((existingItems) => {
                                      return [
                                        ...existingItems.slice(
                                            0,
                                            selectTask.indexOf(item)
                                        ),
                                        ...existingItems.slice(
                                            selectTask.indexOf(item) + 1
                                        ),
                                      ];
                                    });
                                  }
                                }}
                            >
                              {lang.handle(item)}
                            </div>
                        );
                      });
                    })}
                  </div>
                </div>
                <div>
                  <img src={Close} alt="" width={32} onClick={closeModal} />
                </div>
              </div>
          ) : (
              <div className={styles.modalHeader}>
                <div className={styles.modal_elipse}></div>
                <h2 className={styles.modalHeader_text}>{lang.handle("modalINeedTitle")}</h2>
                <div>
                  <img src={Close} alt="" width={32} onClick={closeModal} />
                </div>
              </div>
          )}
          {screenWidth < 1200 && (
              <div className={styles.modalHeader}>
                <div className={styles.modalHeader_tags_img}>
                  <div className={styles.selectedTags}>
                    {tagsSelected.map((itemArr) => {
                      return itemArr.map((item, index) => {
                        return (
                            <div
                                key={item + index}
                                className={styles.selectedTag}
                                onClick={() => {
                                  if (selectTask.includes(item)) {
                                    if (selectTask.length < 2) return;
                                    setSelectTask((existingItems) => {
                                      return [
                                        ...existingItems.slice(
                                            0,
                                            selectTask.indexOf(item)
                                        ),
                                        ...existingItems.slice(
                                            selectTask.indexOf(item) + 1
                                        ),
                                      ];
                                    });
                                  }
                                }}
                            >
                              {lang.handle(item)}
                            </div>
                        );
                      });
                    })}
                  </div>
                </div>
              </div>
          )}
          {screenWidth >= 1200 ? (
              <div className={styles.modalINeedContent}>
            <div className={styles.step1}>
              <div>
                <p>{lang.handle("step")} 1</p>
                <h2>{lang.handle("task")}</h2>
                <div style={{ height: 48 }}></div>
                <div className={styles.tags}>
                  {tasks.map((item, index) => {
                    return (
                      <div
                        key={item + index}
                        className={
                          selectTask.includes(item) ? styles.active_task : ""
                        }
                        onClick={() => {
                          setSelectTask((existingItems) => {
                            return selectTask.includes(item)
                              ? selectTask.length < 2
                                ? existingItems
                                : [
                                    ...existingItems.slice(
                                      0,
                                      selectTask.indexOf(item)
                                    ),
                                    ...existingItems.slice(
                                      selectTask.indexOf(item) + 1
                                    ),
                                  ]
                              : [item, ...existingItems];
                          });
                        }}
                      >
                        {lang.handle(item)}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={styles.container_contacts}>
                <div>
                  <div className={styles.text}>
                    {lang.handle("selectOneOrMoreText")}
                  </div>
                  <div>
                    {screenWidth >= 1440 ? (
                        <InputText
                            id="yourTask"
                            label={lang.handle("yourTask")}
                            value={anyTask}
                            onChange={setAnyTask}
                            required={false}
                            textLength={false}
                            width="400px"
                            type="text"
                        />
                    ) : (
                        <InputText
                            id="yourTask"
                            label={lang.handle("yourTask")}
                            value={anyTask}
                            onChange={setAnyTask}
                            required={false}
                            textLength={false}
                            width="300px"
                            type="text"
                        />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.step2}>
              <div>
                <p>{lang.handle("step")} 2</p>
                <h2>{lang.handle("dueDates")}</h2>
                <div style={{ height: 48 }}></div>
                <div className={styles.tags}>
                  {dueDates.map((item, index) => {
                    return (
                      <div
                        key={item + index}
                        className={
                          selectDueDates.includes(item)
                            ? styles.active_task
                            : ""
                        }
                        onClick={() => {
                          setSelectDueDates(item);
                        }}
                      >
                        {lang.handle(item)}
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* <div style={{ height: 81 }}></div> */}
              <div>
                <h2>{lang.handle("budget")}</h2>
                <div style={{ height: 48 }}></div>
                <div className={styles.tags}>
                  {budget.map((item, index) => {
                    return (
                      <div
                        key={item + index}
                        className={
                          selectBudget.includes(item) ? styles.active_task : ""
                        }
                        onClick={() => {
                          setSelectBudget(item);
                        }}
                      >
                        {lang.handle(item)}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className={styles.step3}>
              <div>
                <p>{lang.handle("step")} 3</p>
                <h2>{lang.handle("contacts")}</h2>
                <div style={{ height: 35 }}></div>
                <div className={styles.container_contacts}>
                  <div>
                    <div className={styles.name_company}>
                      <InputText
                        id="briefName"
                        label={lang.handle("firstName")}
                        value={firstName}
                        onChange={setFirstName}
                        required={false}
                        textLength={false}
                        width="192px"
                        type="text"
                      />
                      <InputText
                        id="briefCompany"
                        label={lang.handle("company")}
                        value={company}
                        onChange={setCompany}
                        required={false}
                        textLength={false}
                        width="192px"
                        type="text"
                      />
                    </div>
                    <InputText
                      id="briefPhone"
                      label={lang.handle("phone")}
                      value={phone}
                      onChange={setPhone}
                      required={true}
                      textLength={false}
                      type="text"
                    />
                    <InputText
                      id="briefEmail"
                      label={lang.handle("email")}
                      value={email}
                      onChange={setEmail}
                      required={true}
                      textLength={false}
                      type="text"
                    />
                    <InputText
                      id="briefComment"
                      label={lang.handle("comment")}
                      value={comment}
                      onChange={setComment}
                      required={false}
                      textLength={false}
                      type="text"
                    />

                    <div className={styles.contacts_attachment}>
                      <div>
                        <label htmlFor="attachment-pc">
                          <span>{lang.handle("attachFile")}</span>
                          <img src={Attachment} alt=""/>
                        </label>
                        <input
                            type="file"
                            multiple
                            className="attachment-input"
                            id="attachment-pc"
                            onChange={handleFileChange}
                        />
                      </div>
                    </div>
                    <div className={styles.can_messenger}>
                      <div>
                        <span>{lang.handle("allowMessengers")}</span>
                        <input
                            type="checkbox"
                            className={styles.checkbox}
                            checked={allowMessengers}
                            onChange={(e) => setAllowMessengers(e.target.checked)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.actionsContainer}>
                {/*<div className={styles.modal_elipse_btn}></div>*/}
                <div className={styles.text}>
                  {lang.handle("modalINeedBtnSendText")}
                </div>
                <Button
                  style={{ width: 223 }}
                  onClick={handleSubmit}
                >
                  {lang.handle("btnSend")}
                </Button>
              </div>
            </div>
          </div>
              ) : (
              <div className={styles.modalINeedContent}>
                {step === 1 && (
                    <div className={styles.step1}>
                  <div>
                    <p>{lang.handle("step")} 1</p>
                    <h2>{lang.handle("task")}</h2>
                    <div style={{ height: 48 }}></div>
                    <div className={styles.tags}>
                      {tasks.map((item, index) => {
                        return (
                            <div
                                key={item + index}
                                className={
                                  selectTask.includes(item) ? styles.active_task : ""
                                }
                                onClick={() => {
                                  setSelectTask((existingItems) => {
                                    return selectTask.includes(item)
                                        ? selectTask.length < 2
                                            ? existingItems
                                            : [
                                              ...existingItems.slice(
                                                  0,
                                                  selectTask.indexOf(item)
                                              ),
                                              ...existingItems.slice(
                                                  selectTask.indexOf(item) + 1
                                              ),
                                            ]
                                        : [item, ...existingItems];
                                  });
                                }}
                            >
                              {lang.handle(item)}
                            </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className={styles.container_contacts}>
                    <div>
                      <div className={styles.text}>
                        {lang.handle("selectOneOrMoreText")}
                      </div>
                      <div>
                        <InputText
                            id="yourTask"
                            label={lang.handle("yourTask")}
                            value={anyTask}
                            onChange={setAnyTask}
                            required={false}
                            textLength={false}
                            width="300px"
                            type="text"
                        />
                      </div>
                    </div>
                  </div>
                      <div className={styles.next_steps}>
                        <div className={styles.next_step} onClick={previousStep}>
                          <img className={styles.next_step_hide} src={scroll} alt="next" />
                          <span className={styles.next_step_hide}>{lang.handle("previousStep")}</span>
                        </div>
                        <div className={styles.next_step} onClick={nextStep}>
                          <span className={styles.next_step_text}>{lang.handle("nextStep")}</span>
                          <img className={styles.next_step_img} src={scroll} alt="next" />
                        </div>
                      </div>
                </div>
                )}
                {step === 2 && (
                    <div className={styles.step2}>
                      <div>
                        <p>{lang.handle("step")} 2</p>
                        <h2>{lang.handle("dueDates")}</h2>
                        <div style={{ height: 24 }}></div>
                        <div className={styles.tags}>
                          {dueDates.map((item, index) => {
                            return (
                                <div
                                    key={item + index}
                                    className={
                                      selectDueDates.includes(item)
                                          ? styles.active_task
                                          : ""
                                    }
                                    onClick={() => {
                                      setSelectDueDates(item);
                                    }}
                                >
                                  {lang.handle(item)}
                                </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* <div style={{ height: 81 }}></div> */}
                      <div>
                        <h2>{lang.handle("budget")}</h2>
                        <div style={{ height: 24 }}></div>
                        <div className={styles.tags}>
                          {budget.map((item, index) => {
                            return (
                                <div
                                    key={item + index}
                                    className={
                                      selectBudget.includes(item) ? styles.active_task : ""
                                    }
                                    onClick={() => {
                                      setSelectBudget(item);
                                    }}
                                >
                                  {lang.handle(item)}
                                </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className={styles.next_steps}>
                        <div className={styles.next_step} onClick={previousStep}>
                          <img className={styles.previous_step_img} src={scroll} alt="next" />
                          <span className={styles.next_step_text}>{lang.handle("previousStep")}</span>
                        </div>
                        <div className={styles.next_step} onClick={nextStep}>
                          <span className={styles.next_step_text}>{lang.handle("nextStep")}</span>
                          <img className={styles.next_step_img} src={scroll} alt="next" />
                        </div>
                      </div>
                    </div>
                )}
                {step === 3 && (
                    <div className={styles.step3}>
                      <div>
                        <p>{lang.handle("step")} 3</p>
                        <h2>{lang.handle("contacts")}</h2>
                        <div style={{ height: 0 }}></div>
                        <div className={styles.container_contacts}>
                          <div>
                            <div className={styles.name_company}>
                              <InputText
                                  id="briefName"
                                  label={lang.handle("firstName")}
                                  value={firstName}
                                  onChange={setFirstName}
                                  required={false}
                                  textLength={false}
                                  width="192px"
                                  type="text"
                              />
                              <InputText
                                  id="briefCompany"
                                  label={lang.handle("company")}
                                  value={company}
                                  onChange={setCompany}
                                  required={false}
                                  textLength={false}
                                  width="192px"
                                  type="text"
                              />
                            </div>
                            <InputText
                                id="briefPhone"
                                label={lang.handle("phone")}
                                value={phone}
                                onChange={setPhone}
                                required={true}
                                textLength={false}
                                type="text"
                            />
                            <InputText
                                id="briefEmail"
                                label={lang.handle("email")}
                                value={email}
                                onChange={setEmail}
                                required={true}
                                textLength={false}
                                type="text"
                            />
                            <InputText
                                id="briefComment"
                                label={lang.handle("comment")}
                                value={comment}
                                onChange={setComment}
                                required={false}
                                textLength={false}
                                type="text"
                            />

                            <div className={styles.contacts_attachment}>
                              <div>
                                <label htmlFor="attachment-mobile">
                                  <span>{lang.handle("attachFile")}</span>
                                  <img src={Attachment} alt=""/>
                                </label>
                                <input
                                    type="file"
                                    multiple
                                    className="attachment-input"
                                    id="attachment-mobile"
                                    onChange={handleFileChange}
                                />
                              </div>
                            </div>
                            <div className={styles.can_messenger}>
                              <div>
                                <span>{lang.handle("allowMessengers")}</span>
                                <input type="checkbox" className={styles.checkbox}/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.actionsContainer}>
                        <div className={styles.text}>
                        {lang.handle("modalINeedBtnSendText")}
                        </div>
                        {/*<div className={styles.modal_elipse_btn}></div>*/}
                        <Button
                            style={{ width: 223 }}
                            onClick={() => {
                              setIsOpenINeed(false);
                              setIsOpenModalConfirmation(true);
                            }}
                        >
                          {lang.handle("btnSend")}
                        </Button>
                      </div>
                      <div className={styles.next_steps}>
                        <div className={styles.next_step} onClick={previousStep}>
                          <img className={styles.previous_step_img} src={scroll} alt="next" />
                          <span className={styles.next_step_text}>{lang.handle("previousStep")}</span>
                        </div>
                        <div className={styles.next_step} onClick={nextStep}>
                          <span className={styles.next_step_hide}>{lang.handle("nextStep")}</span>
                          <img className={styles.next_step_hide} src={scroll} alt="next" />
                        </div>
                      </div>
                    </div>
                )}
              </div>
          )}
        </div>
      </div>
    </>
  );
};

export const ModalINeed = withLocale(ModalINeedComponent);
