import React from "react";

import { withLocale } from "../../contexts/locale.context";
import styles from "../../css/Modal.module.css";
import Button from "../UI/Button";
import background from "../../images/background-brief.png";

const ModalErrorComponent = ({ setIsOpenModalError, lang }) => {
  const closeModal = () => {
    document.getAnimations().forEach((animation) => {
      animation.playbackRate = 1;
    });
    // document.body.style.overflow = "scroll";
    setIsOpenModalError(false);
  };

  const contacts = [
    {
      id: "asb5d4g5f6gd1",
      comment: lang.handle("footerContactsAddreesPhoneComment01"),
      phone: lang.handle("footerContactsAddreesPhone01"),
    },
    {
      id: "5dsdf4fs68ff1",
      comment: lang.handle("footerContactsAddreesPhoneComment02"),
      phone: lang.handle("footerContactsAddreesPhone02"),
    },
  ];

  return (
    <>
      <div className={styles.blurBG} onClick={closeModal} />
      <div className={styles.centered}>
        <div className={styles.modalConfirmation} style={{backgroundImage: `url(${background})`}}>
          <div className={styles.modalConfirmationContent}>
            <h2 className="header_text_gradient">
              {lang.handle("ModalErrorTitle")}
            </h2>
            <div style={{ height: 11 }}></div>
            <span className={styles.modalConfirmationContent}>
              {lang.handle("ModalErrorText")}
            </span>
            <div style={{ height: 76 }}></div>
            <div className={styles.contact_block}>
              <div className={styles.contacts}>
                {contacts.map((item, index) => {
                  return (
                      <ul key={index + item.id}>
                        <li>
                          <span id="footerContactsAddrees01">{item.comment}</span>
                        </li>
                        <li>
                          <span id="footerContactsAddreesPhone01">{item.phone}</span>
                        </li>
                      </ul>
                  );
                })}
              </div>
              <div className={styles.telegram}>
                <ul>
                  <li>info@vercloud.ru</li>
                  <li>telegram</li>
                  <li>whatsapp</li>
                </ul>
              </div>
            </div>
            <div className={styles.actionsContainer}>
              <Button style={{width: 223}} onClick={closeModal}>
                {lang.handle("btnClose")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ModalError = withLocale(ModalErrorComponent);
