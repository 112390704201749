import React, { useState } from "react";

import { LocaleContext } from "./locale.context";
import en from "../locales/en.locale.json";
import ru from "../locales/ru.locale.json";

const translations = {
  ru,
  en,
};

function Locale(props) {
  const [language, setChangeLanguage] = useState("ru");

  function handle(string) {
    return translations[language][string] || string;
  }

  const selectOption = [];
  Object.keys(translations).map((lang) => selectOption.push(lang));

  return (
    <LocaleContext.Provider
      value={{
        current: language,
        setChangeLanguage: setChangeLanguage,
        handle: handle,
      }}
    >
      {props.children}
    </LocaleContext.Provider>
  );
}

export { Locale };
