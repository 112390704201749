import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-scroll";

import { withLocale } from "../contexts/locale.context";
import logo from "../images/logo-vc-white.svg";
import menuIcon from "../images/burger.svg";
import Button from "./UI/Button";

function NavBarComponent({ setIsOpenINeed, setIsOpenMenu, pageload, lang, setTrigger }) {
  const [opacity, setOpacity] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const imgRef = useRef(null);

  const updateOpacity = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const newOpacity = scrollTop / (window.innerHeight * 0.2);
    setOpacity(newOpacity);
  };

  useEffect(() => {
    window.addEventListener("scroll", updateOpacity);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener("scroll", updateOpacity);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function millisecondsToTime(milli) {
    let milliseconds = Math.trunc(milli % 1000);
    let seconds = Math.floor((milli / 1000) % 60);
    return seconds + "s " + milliseconds + "ms";
  }

  function openModal() {
    document.getAnimations().forEach((animation) => {
      animation.playbackRate = 0;
    });
    setIsOpenINeed(true);
  }

  function openMenu() {
    document.getAnimations().forEach((animation) => {
      animation.playbackRate = 0;
    });
    setIsOpenMenu(true);
  }

  const changeLanguage = () => {
    lang.current === "ru"
      ? lang.setChangeLanguage("en")
      : lang.setChangeLanguage("ru");
    setTrigger(true);
  };

  return (
    <nav
      className="nav_container"
      style={{
        backgroundColor: `rgba(0, 0, 0, ${opacity})`,
        transition: "background-color 0.1s ease",
      }}
    >
      {windowWidth >= 1000 ? (
          <div className="nav_wrapper">
            <div className="menu-start">
              <Link to="main" smooth={true} duration={2000}>
                <img
                    src={logo}
                    className="logo"
                    alt="vercloud logo"
                />
              </Link>
              <ul className="menu-links">
                <li>
                  <Link to="services" smooth={true} duration={2000}>
                    <div className="linkmenu">{lang.handle("menuServices")}</div>
                  </Link>
                </li>
                <li>
                  <Link to="cases" smooth={true} duration={2000}>
                    <div className="linkmenu">{lang.handle("menuCases")}</div>
                  </Link>
                </li>
                <li>
                  <Link to="about" smooth={true} duration={2000}>
                    <div className="linkmenu">{lang.handle("menuAbout")}</div>
                  </Link>
                </li>
                <li>
                  <Link to="vacancy" smooth={true} duration={2000}>
                    <div className="linkmenu">{lang.handle("menuVacancy")}</div>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="menu-end">
              <ul className="menu-buttons">
                <li>
                  <div className="linkmenu">
                    <div onClick={changeLanguage}>{lang.handle("panelNavLang")}</div>
                  </div>
                </li>
                <li>
                  <Button animated onClick={openModal}>
                    {lang.handle("btnfillBrief")}
                  </Button>
                </li>
              </ul>
            </div>
          </div>
      ) : (
          <div className="nav_wrapper">
            <div className="lang_mobile" onClick={changeLanguage}>
              <p>{lang.handle("panelNavLang")}</p>
            </div>
            <Link to="main" smooth={true} duration={2000}>
              <img
                  src={logo}
                  className="logo"
                  alt="vercloud logo"
              />
            </Link>
            <button
                className="menu-button"
                animated
                onClick={openMenu}
            >
              <img
                  ref={imgRef}
                  className="menu-icon"
                  src={menuIcon}
                  alt="menu"
              />
            </button>
          </div>
      )}
      <div className="loading">{millisecondsToTime(pageload)} - {windowWidth}px</div>
    </nav>
  );
}

export const NavBar = withLocale(NavBarComponent);
