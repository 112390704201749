import React, { Profiler } from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import App from "./App";

const callback = (id, phase, actualTime, baseTime, startTime, commitTime) => {
  // console.log(`${id}'s ${phase} phase:`);
  // console.log(`Actual time: ${actualTime}`);
  // console.log(`Base time: ${baseTime}`);
  // console.log(`Start time: ${startTime}`);
  // console.log(`Commit time: ${commitTime}`);
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Profiler id="App" onRender={callback}>
      <App />
    </Profiler>
  </React.StrictMode>
);
