import React, { useState } from "react";
import styles from "./inputText.module.css";

const InputText = ({
  id,
  label,
  required,
  width,
  textLength,
  maxLength,
  value = "",
  onChange,
}) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className={styles.input_wrapper} style={{ width }}>
      <input
        id={id}
        className={styles.input}
        value={value}
        onChange={handleChange}
        placeholder=""
        maxLength={maxLength}
      />
      <label htmlFor={id} className={styles.label}>
        <span className={styles.labelText}>{label}</span>
        {required && <span className={styles.labelRequired}>*</span>}
      </label>
      {textLength && (
        <p className={styles.textLength}>{`${value.length} / ${maxLength}`}</p>
      )}
    </div>
  );
};

export default InputText;
