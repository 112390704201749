import React, { useState, useEffect, useRef } from "react";

import { withLocale } from "../contexts/locale.context";
import "./EncodedHeader.css";
import {Digits} from "./Digits";
import {DigitsMobile} from "./DigitsMobile";

function EncodedHeaderComponent({ lang, trigger, setTrigger }) {
  const [header, setHeader] = useState(lang.handle("prodAppliпше cations"));
  // const [currentLang, setCurrentLang] = useState(lang.current);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (timerId.current === null) {
      decodingAnimation();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    if (trigger) {
      decodingAnimation();
      setTrigger(false);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [trigger]); // eslint-disable-line

  const symbols = "!@#$%^&*()_+1234567890-=|?/>.<,}]{[";
  const maxSymbols = 100;
  const minDuration = 4000;
  const maxDuration = 4100;
  const step = 30;
  const direction = "forward" || "backward" || "";
  let timerId = useRef(null);
  let animationSettings = [];
  let allTicks = 0;
  const textStart = lang.handle("prodStart");
  const textEnd = lang.handle("prodEnd");

  function getRandomNumber(x, y) {
    return Math.floor(Math.random() * (y - x + 1)) + x;
  }

  function decodingAnimation() {
    const symbolsArray = symbols.split("");
    const numberOfSymbols = symbolsArray.length;
    const lettersStart = textStart.split("");
    const letters = textEnd.split("");
    for (let i = 0; i < letters.length; i++) {
      animationSettings.push({
        final: null,
        current: null,
        symbols: getRandomNumber(1, maxSymbols),
        duration: getRandomNumber(minDuration, maxDuration) - minDuration,
        ticks: 0,
        currentTick: 0,
        letters: [],
        timers: [],
      });
    }
    if (direction === "forward") {
      animationSettings.sort(function (a, b) {
        return a.duration - b.duration;
      });
    } else if (direction === "backward") {
      animationSettings.sort(function (a, b) {
        return b.duration - a.duration;
      });
    }
    letters.forEach((item, index) => {
      animationSettings[index].final = item;
      animationSettings[index].current = lettersStart[index] === '~' ? '' : lettersStart[index];
    });
    animationSettings = animationSettings.map((item) => {
      let ticks = Math.ceil(item.duration / item.symbols / step) * step;
      let timers = [];
      let letters = [];
      for (let i = 1; i <= item.symbols; i++) {
        timers.push(minDuration + ticks * i);
        if (i === item.symbols) {
          letters.push(item.final);
        } else {
          letters.push(symbolsArray[getRandomNumber(0, numberOfSymbols - 1)]);
        }
      }
      return {
        final: item.final,
        current: item.current,
        symbols: item.symbols,
        duration: item.duration,
        currentTick: item.currentTick,
        ticks: ticks,
        letters: letters,
        timers: timers,
      };
    });
    console.log("animationSettings: ", animationSettings);
    // setCurrentLang(lang.current);
    timerId = setInterval(() => {
      //   console.log(animationSettings);
      let currentArray = animationSettings.map((item) => {
        return item.current;
      });
      // console.log("currentLang:", lang.current, currentLang);
      setHeader(currentArray.join(""));
      if ((allTicks++ > maxDuration / step + 100)) {
        console.log("clearTimeout ~ timerId");
        clearInterval(timerId);
        console.log("timerId ~ timerId: ", timerId);
      }
    }, step);
    console.log("timerId:", timerId);

    animationSettings.forEach((item, index) => {
      item.timers.forEach((timer, indexOfTimers) => {
        //  eslint-disable-next-line
        let timerIdAnimation = setTimeout(() => {
          animationSettings[index].current = item.letters[indexOfTimers];
        }, timer);
        // console.log("timerIdAnimation", timerIdAnimation);
      });
    });
  }

  return (
      <div>
        {windowWidth > 1300 ? (
            <div id="encodedHeader" className={lang.current === "en" ?
                "encoded_header_white encoded_header_en" :
                "encoded_header_white encoded_header_ru"}>
              {lang.handle("headerSubtitle")}
              &#x3C;/
              {header}
              &#x3E;
            </div>
        ) : (
            <div id="encodedHeader" className="header_text_gradient">
              {lang.handle("headerSubtitle")}
              &#x3C;/
              {header}
              &#x3E;
            </div>
        )}
      </div>
    );
}

export const EncodedHeader = withLocale(EncodedHeaderComponent);
