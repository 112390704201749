import React, {useEffect, useState} from "react";

import { withLocale } from "../contexts/locale.context";
import "./header.css";
import scroll from "../images/scroll-down.svg";
import { EncodedHeader } from "./EncodedHeader";
import headerImage from "../images/header-image.png";

function HeaderComponent({ lang, trigger, setTrigger }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // eslint-disable-line

  return (
    <header id="main" className="header-heading">
      <div className="header-scroll"></div>
      <div className="header-line">
        <div className="header-line_block">
            <div id="headerTitle" className="header_title">
                <h1 className="header_text_gradient">{lang.handle("headerTitle")}</h1>
            </div>
            <div id="header-" className="header-small-description">
                {lang.handle("headerSmallDescription")}
            </div>
        </div>
          <div className="header-line_block">
              <div className={lang.current === "en" ?
                  "description description-en" :
                  "description description-ru"}
              >
                  {lang.handle("headerDescription")}
              </div>
              <div className="header-images">
                  <div className="header-ellipse"></div>
                  <img src={headerImage} className={lang.current === "en" ?
                      (windowWidth > 351 ? "header-image image-en" : "header-image-small") :
                      "header-image image-ru"} alt="" />
                  <div className="header-circle"></div>
              </div>
              <div className="header-subtitle">
                  <EncodedHeader trigger={trigger} setTrigger={setTrigger} />
              </div>
          </div>
      </div>
      <div className="header-product-list">
        <p>&#123;</p>
        <ul>
          <li className="header-product">
            {lang.handle("prodApplications")},
          </li>
          <li className="header-product">
              {lang.handle("prodServices")},
          </li>
          <li className="header-product">
            {lang.handle("prodPlatforms")},
          </li>
            <li className="header-product">
                {lang.handle("prodIntegrations")},
            </li>
          <li className="header-product">
            {lang.handle("prodInterfaces")}
          </li>
        </ul>
        <p>&#125;</p>
      </div>
      <div className="header-scroll">
        <img src={scroll} alt="scroll down" />
      </div>
    </header>
  );
}

export const Header = withLocale(HeaderComponent);
