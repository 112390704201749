import React from "react";

import { withLocale } from "../contexts/locale.context";
import "./digits.css";
import background from "../images/background-block.png";

function DigitsComponent({ lang }) {
  const now = new Date();
  const years = now.getFullYear() - 2008;

  const digits = [
    {
      title: years,
      sub: lang.handle("digitYearsSub"),
      text: lang.handle("digitYearsText"),
    },
    {
      title: lang.handle("digitProjects"),
      sub: lang.handle("digitProjectsSub"),
      text: lang.handle("digitProjectsText"),
    },
    {
      title: lang.handle("digitUsers"),
      sub: lang.handle("digitUsersSub"),
      text: lang.handle("digitUsersText"),
    },
  ];

  return (
    <div id="digits" className="digits-wrapper">
      <div className="digits-backgr-container">
        <div id="digitsTitle" className="header_item_digits">
          {lang.handle("digitsTitle")}
        </div>
        <ul>
          {digits.map((item, index) => {
            return (
              <li key={index}>
                <div>
                  <h1>/{item.title}/</h1>
                  <span>{item.sub}</span>
                </div>
                <div style={{ height: 40 }}></div>
                <p>{item.text}</p>
              </li>
            );
          })}
        </ul>
      </div>

      <div style={{ height: 300 }}></div>

      {/* <div className="digits-backgr-container"> */}
      <img src={background} className="digits-backgr" alt="" />
      {/* </div> */}
    </div>
  );
}

export const Digits = withLocale(DigitsComponent);
