import React, { useState, useEffect } from "react";

import "./cases.css";
import { withLocale } from "../contexts/locale.context";
import IconDownload from "../images/arrow-down_lineless.svg";
import cases_projects from "../data/cases_projects";
import cases_tags from "../data/cases_tags.json";
import Button from "./UI/Button";

function CasesComponent({ lang }) {
  const arrayFilteredTags = [];

  const allProjects = cases_projects.map((project, index) => {
    return {
      title: lang.handle(project.title),
      text: lang.handle(project.text),
      image: project.image,
      tags: project.tags,
    };
  });

  const count = 2;

  const [isActive, setIsActive] = useState(null);
  const [projects, setProjects] = useState(allProjects);
  const [index, setIndex] = useState(0);
  const [countProjects, setCountProjects] = useState(count);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const tags = cases_tags.map((tag) => {
    const name_tag = cases_projects.filter((item) =>
      item.tags.includes(tag.tag)
    );
    arrayFilteredTags.push(name_tag);
    return {
      name: lang.handle(tag.name),
      tag: tag.tag,
      count: name_tag.length,
    };
  });

  const onClickTag = (event) => {
    const index = +event.target.dataset.index;
    setIndex(index);
    setIsActive(index);
    const project = arrayFilteredTags[index].map((project, index) => {
      return {
        title: lang.handle(project.title),
        text: lang.handle(project.text),
        image: project.image,
        tags: project.tags,
      };
    });
    setCountProjects(count);
    setProjects(project);
  };

  const getMoreProjects = () => {
    setCountProjects(projects.length);
  };

  useEffect(() => {
    setProjects(
      arrayFilteredTags[index].map((project) => {
        return {
          title: lang.handle(project.title),
          text: lang.handle(project.text),
          image: project.image,
          tags: project.tags,
        };
      })
    );
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [lang.current]); // eslint-disable-line

  return (
    <div id="cases" className="cases">
      <div>
        <div className="header_cases_container">
          <div className="header_cases">
            <h1 className="header_text_gradient">{lang.handle("casesHeader")}</h1>
          </div>
          <p>{windowWidth > 1000 ? lang.handle("casesText") : lang.handle("casesShortText")}</p>
        </div>

        <div className="container_cases_tags">
          <ul className="cases_tags">
            {tags.map((item, index) => {
              return (
                <li key={index}>
                  <span
                    data-index={index}
                    className={
                      (item.tag === "all" && isActive === null) ||
                      index === isActive
                        ? "active_tag"
                        : ""
                    }
                    onClick={onClickTag}
                  >
                    / {item.name} / <span className="cases_tags__count">{item.count}</span>
                  </span>
                </li>
              );
            })}
          </ul>

          {/* <div style={{ height: 52 }}></div> */}

          <div
            className="cases_select"
            onClick={() => console.log("Выбрать отрасль")}
          >
            <span>{lang.handle("casesSelectIndustry")}</span>
            <img src={IconDownload} alt="" />
          </div>
        </div>

        {/* <div style={{ height: 52 }}></div> */}

        <div className="container_cases_projects">
          {projects.slice(0, countProjects).map((item, index) => {
            return (
              <div className="cases_project" key={index}>
                <div>
                  <img src={item.image} alt="cases" />
                </div>
                <h3>{item.title}</h3>
                <p id="id_text_popap" className="text_popap">
                  {item.text}
                </p>
              </div>
            );
          })}
        </div>

        {/* <div className="container_cases_projects_row2">
          {projects.map((item, index) => {
            return (
              <div className="cases_project_col2" key={index}>
                <div
                  className="photo-container2"
                  style={{ backgroundImage: `url(${item.image} ` }}
                ></div>
                <h2>{item.title}</h2>
                <div className="slide2">
                  <p>{item.text}</p>
                </div>
              </div>
            );
          })}
        </div> */}

        {/* <div style={{ height: 72 }}></div> */}

        {projects.length > countProjects && (
          <div className="cases_project_btn_container button_center">
            <div className="flash_container">
              <div>
                <Button style={{ width: 177 }} onClick={getMoreProjects}>
                  {lang.handle("btnShowMore")}
                </Button>
              </div>
              <div className="cases_project_flash btn_cases_project_flash"></div>
            </div>
          </div>
        )}

        {/* <div style={{ height: 172 }}></div> */}
      </div>
    </div>
  );
}

export const Cases = withLocale(CasesComponent);
