const cases_projects = [
  {
    title: "casesProjectTitle01",
    text: "casesProjectText01",
    image: require("../images/cases/case_01.png"),
    tags: ["all", "webapps"],
  },
  {
    title: "casesProjectTitle02",
    text: "casesProjectText02",
    image: require("../images/cases/case_02.png"),
    tags: ["all", "webapps"],
  },
  {
    title: "casesProjectTitle03",
    text: "casesProjectText03",
    image: require("../images/cases/case_03.png"),
    tags: ["all", "mobileapps"],
  },
  {
    title: "casesProjectTitle04",
    text: "casesProjectText04",
    image: require("../images/cases/case_04.png"),
    tags: ["all", "webapps", "mobileapps"],
  },
  {
    title: "casesProjectTitle05",
    text: "casesProjectText05",
    image: require("../images/cases/case_02.png"),
    tags: ["all", "mobileapps"],
  },
  {
    title: "casesProjectTitle06",
    text: "casesProjectText06",
    image: require("../images/cases/case_01.png"),
    tags: ["all", "mobileapps"],
  },
];

export default cases_projects;
