import React from "react";

import { withLocale } from "../../contexts/locale.context";
import styles from "../../css/Modal.module.css";
import Button from "../UI/Button";
import background from "../../images/background-brief.png";

const ModalConfirmationComponent = ({ setIsOpenModalConfirmation, lang }) => {
  const closeModal = () => {
    document.getAnimations().forEach((animation) => {
      animation.playbackRate = 1;
    });
    // document.body.style.overflow = "scroll";
    setIsOpenModalConfirmation(false);
  };

  return (
    <>
      <div className={styles.blurBG} onClick={closeModal} />
      <div className={styles.centered}>
        <div className={styles.modalConfirmation} style={{backgroundImage: `url(${background})`}}>
          <div className={styles.modalConfirmationContent}>
            <h2 className="header_text_gradient">
              {lang.handle("ModalConfirmationTitle")}
            </h2>
            <div style={{ height: 11 }}></div>
            <span className={styles.modalConfirmationContent}>
              {lang.handle("ModalConfirmationText")}
            </span>
            <div style={{ height: 76 }}></div>
            <div className={styles.actionsContainer}>
              <Button style={{ width: 223 }} onClick={closeModal}>
                {lang.handle("btnClose")}
              </Button>
              {/* <button className={styles.OKBtn} onClick={closeModal}>
                {lang.handle("ModalConfirmationBtnOK")}
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ModalConfirmation = withLocale(ModalConfirmationComponent);
