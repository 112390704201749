import React, {useEffect, useState} from "react";

import { withLocale } from "../contexts/locale.context";
import "./technologies.css";
import technologies_name from "../data/technologies_name.json";
import technologies_tags from "../data/technologies_tags.json";

function TechnologiesComponent({ lang }) {
  const [itemsTechno, setItemsTechno] = useState(technologies_name);
  const [isActive, setIsActive] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const arrayFilteredTags = [];

  const tags = technologies_tags.map((tag) => {
    const name_tag = technologies_name.filter((item) =>
      item.tags.includes(tag.tag)
    );
    arrayFilteredTags.push(name_tag);
    return {
      name: lang.handle(tag.name),
      tag: tag.tag,
      count: name_tag.length,
    };
  });

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // eslint-disable-line

  const onClickTag = (event) => {
    const index = +event.target.dataset.index;
    setIsActive(index);
    setItemsTechno(arrayFilteredTags[index]);
  };

  return (
    <div id="technologies" className="technologies">
        <div className="header_technologies_container">
            <div>
                <h1 className="header_text_gradient">{lang.handle("technoHeader")}</h1>
            </div>
            <p>{windowWidth > 1000 ? lang.handle("technoDescription") : lang.handle("technoShortDescription")}</p>
        </div>

        <div className="container_technologies_tags">
        <ul className="technologies_tags">
          {tags.map((item, index) => {
            return (
              <li key={index}>
                <span
                  data-index={index}
                  className={
                    (item.tag === "all" && isActive === null) ||
                    index === isActive
                      ? "active_tag"
                      : ""
                  }
                  onClick={onClickTag}
                >
                  / {item.name} /{item.count}
                </span>
              </li>
            );
          })}
        </ul>
      </div>

      <div style={{ height: 43 }}></div>

      <div className="technologies_items">
        <ul className="grid_list">
          {itemsTechno.map((block, index) => {
            return (
              <li key={index} className="grid_list_items">
                <span>{block.name}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export const Technologies = withLocale(TechnologiesComponent);
