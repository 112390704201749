import React from "react";
import { Link } from "react-scroll";

import { withLocale } from "../contexts/locale.context";
import logoMincifra from "../images/logo-MincifryRF.svg";
import "./footer.css";

function FooterComponent({ lang }) {
  const LinkItems = [
    {
      id: "5v4rpvxnmgv4xxXxXxXx6x",
      link: "Services",
      name: lang.handle("footerServices"),
    },
    {
      id: "xdv2bd48bnsscszncvgvzvd",
      link: "Cases",
      name: lang.handle("footerCases"),
    },
    {
      id: "d45v65d5vx6v6vasdsadsa4x",
      link: "About",
      name: lang.handle("footerAbout"),
    },
    {
      id: "szv5vxvb3czcscszsczbfcfbb",
      link: "Vacancy",
      name: lang.handle("footerVacancy"),
    },
  ];

  const contacts = [
    {
      id: "asb5d4g5f6gd",
      address: lang.handle("footerContactsAddrees01"),
      map: lang.handle("lookMap"),
      phone: lang.handle("footerContactsAddreesPhone01"),
    },
    {
      id: "5dsdf4fs68ff",
      address: lang.handle("footerContactsAddrees02"),
      map: lang.handle("lookMap"),
      phone: lang.handle("footerContactsAddreesPhone02"),
    },
  ];

  return (
    <footer id="footer">
      {/* <div style={{ height: 400 }}></div> */}

      <div className="main-content-area">
        <div className="footer_headers">
          <h3>{lang.handle("footerLinks")}</h3>
        </div>
        <div className="footer_headers">
          <h3>{lang.handle("footerNavigation")}</h3>
        </div>
        <div className="footer_headers">
          <h3>{lang.handle("footerContacts")}</h3>
        </div>
        <div className="row-border"></div>
      </div>

      <div className="main-content-area">
        <div className="holder links">
          <div className="footer_headers2">
            <h3>{lang.handle("footerLinks")}</h3>
          </div>
          <ul>
            <li>
              <a href="##">{lang.handle("footerLinksPrivacyPolicy")}</a>
            </li>
            <li>
              <a href="##">{lang.handle("footerLinksOffer")}</a>
            </li>
            <li>
              <a href="##">{lang.handle("footerRequisites")}</a>
            </li>
          </ul>
        </div>
        <div className="holder navigation">
          <div className="footer_headers2">
            <h3>{lang.handle("footerNavigation")}</h3>
          </div>
          <ul>
            {LinkItems.map((item, index) => {
              return (
                <li key={index + item.id}>
                  <Link
                    to={item.link.toLowerCase()}
                    smooth={true}
                    duration={2000}
                  >
                    <div>{item.name}</div>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="holder contacts">
          <div className="footer_headers2">
            <h3>{lang.handle("footerContacts")}</h3>
          </div>
          {contacts.map((item, index) => {
            return (
              <ul key={index + item.id}>
                <li>
                  <span id="footerContactsAddrees01">{item.address}</span>
                </li>
                <li>
                  <a id="footerContactsAddreesMap01" href="##">
                    {item.map}
                  </a>
                </li>
                <li>
                  <span id="footerContactsAddreesPhone01">{item.phone}</span>
                </li>
              </ul>
            );
          })}
        </div>
      </div>

      <div className="copyright">
        <div className="mincifra">
          <img src={logoMincifra} alt="logo-Mincifra" />
          <div>
            <span>{lang.handle("footerIT")}</span>
            <span>{lang.handle("footerCopyright")}</span>
          </div>
        </div>
        <div className="telegram">
          <ul>
            <li>info@vercloud.ru</li>
            <li>telegram</li>
            <li>whatsapp</li>
          </ul>
        </div>
      </div>

      {/* <div style={{ height: 400 }}></div> */}
    </footer>
  );
}

export const Footer = withLocale(FooterComponent);
