import React, { useRef, useState } from 'react';

import { withLocale } from "../contexts/locale.context";
import "./digitsMobile.css";
import background from "../images/background-block-mobile.png";

function DigitsMobileComponent({ lang }) {
  const now = new Date();
  const years = now.getFullYear() - 2008;

  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.clientX - containerRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    containerRef.current.scrollLeft = e.clientX - startX;
  };

  const digits = [
    {
      title: years,
      sub: lang.handle("digitYearsSub"),
      text: lang.handle("digitYearsShortText"),
    },
    {
      title: lang.handle("digitProjects"),
      sub: lang.handle("digitProjectsSub"),
      text: lang.handle("digitProjectsShortText"),
    },
    {
      title: lang.handle("digitUsers"),
      sub: lang.handle("digitUsersSub"),
      text: lang.handle("digitUsersShortText"),
    },
  ];

  return (
    <div className="digits-mobile_wrapper"
         ref={containerRef}
         onMouseDown={handleMouseDown}
         onMouseUp={handleMouseUp}
         onMouseMove={handleMouseMove}
         // style={{
         //   overflowX: 'hidden',
         //   userSelect: 'none',
         //   width: '100%',
         //   height: '43rem',
         //   cursor: isDragging ? 'grabbing' : 'grab',
         // }}
    >
      <div className="digits-backgr-container_mobile">
        <div id="digitsTitle" className="header_item_mobile">
          {lang.handle("digitsTitle")}
        </div>
        <ul>
          {digits.map((item, index) => {
            return (
              <li key={index}>
                <div>
                  <h1>/{item.title}/</h1>
                  <span>{item.sub}</span>
                </div>
                <p>{item.text}</p>
              </li>
            );
          })}
        </ul>
      </div>
      <img src={background} className="digits-backgr_mobile" alt="" />
    </div>
  );
}

export const DigitsMobile = withLocale(DigitsMobileComponent);
