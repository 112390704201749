import React, {useEffect, useState} from "react";

import { withLocale } from "../contexts/locale.context";
import "./about.css";
import about_principles from "../data/about_principles.json";
import About_Company from "../images/about.png";
import Button from "./UI/Button";

function AboutComponent({ lang }) {
  const principles = about_principles;

  const aboutImageStyle = {
    backgroundImage: `url(${About_Company})`,
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // eslint-disable-line

  return (
    <div id="about" className="about">
      <div className="header_about_container">
        <div>
          <h1 className="header_text_gradient">{lang.handle("aboutCompany")}</h1>
        </div>
        <p>{windowWidth > 1000 ? lang.handle("aboutTitle") : lang.handle("aboutShortTitle")}</p>
      </div>

      <div className="container_about">
        <div>
          <div className="about_company_image" style={aboutImageStyle}></div>
        </div>
        <div className="btn_presentation">
          <div className="container_about_text">{lang.handle("aboutText")}</div>
          <div className="btn_container">
            <div className="flash_container">
              <div>
                <Button style={{ width: 255 }} onClick={() => {}}>
                  {lang.handle("btnDownloadPresentation")}
                </Button>
              </div>
              <div className="flash btn_flash"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="container_about_principles">
        <div className="principles_title">{lang.handle("aboutSubSection")}</div>
        <div className="principles_list">
          {principles.map((item, index) => {
            return (
              <div key={index + item.id} className="principles_list_item">
                <div>
                  <div>/ 0{index + 1} /</div>
                </div>
                <div>
                  <h3>{lang.handle(item.title)}</h3>
                  <p>{lang.handle(item.text)}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export const About = withLocale(AboutComponent);
