import React from "react";

import { withLocale } from "../contexts/locale.context";
// import logo from "../images/logo-vc.svg";
import "./vacancy.css";
import Button from "./UI/Button";
import vacancy_position from "../data/vacancy_position.json";
import vacancy_skills from "../data/vacancy_skills.json";
import background from "../images/background-block.png";

function VacancyComponent({ lang }) {
  const vacancy = vacancy_position.map((position) => {
    return {
      id: lang.handle(position.id),
      position: lang.handle(position.position),
      experience: lang.handle(position.experience),
      level: lang.handle(position.level),
      location: lang.handle(position.location),
    };
  });

  const internship = vacancy_skills.map((slill) => {
    return lang.handle(slill);
  });

  return (
    <div id="vacancy" className="vacancy">
      <div className="header_vacancy_container">
        <div>
          <h1 className="header_text_gradient">{lang.handle("vacancy")}</h1>
        </div>
        <p>{lang.handle("vacancyDescription")}</p>
      </div>
      <div className="container_vacancy">
        {vacancy.map((item, index) => {
          return (
            <div key={index + item.id} className="vacancy_item">
              <div>{item.position}</div>
              <div>
                <p>{lang.handle("vacancyExperience")}</p>
                <p>{item.experience}</p>
              </div>
              <div>
                <p>{lang.handle("vacancyLevel")}</p>
                <p>{item.level}</p>
              </div>
              <div>
                <p>{lang.handle("vacancyLocation")}</p>
                <p>{item.location}</p>
              </div>
              <div className="header-images">
                <Button
                  style={{ width: 180 }}
                  onClick={() => {
                    alert("Ну, ты молодец, что нажал");
                  }}
                >
                  {lang.handle("btnVacancyRespond")}
                </Button>
                <div className="btn-flash-circle"></div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="vacancy-backgr-container">
        <img src={background} className="vacancy-backgr" alt="" />
      </div>

      <div className="header2_vacancy_container">
        <div>
          <h1 className="header_text_gradient">{lang.handle("todayInIT")}</h1>
        </div>
      </div>

      <div className="header2_vacancy_description">
        <p>{lang.handle("educationalTitle")}</p>
      </div>

      <div className="internship_vacancy_container">
        <div className="internship_vacancy_item">
          {internship.map((item, index) => {
            return (
              <div key={item + index}>
                <div className="header-images">
                  <p>+</p>
                  <div className="btn-flash-circle"></div>
                </div>
                <p>{item}</p>
              </div>
            );
          })}
          <div className="header-images">
            <Button
              style={{ width: 225 }}
              onClick={() => {
                alert("Ну, ты молодец, что нажал");
              }}
            >
              {lang.handle("btnFillBid")}
            </Button>
            <div className="btn-flash-circle"></div>
          </div>
        </div>
      </div>
      <div style={{ height: 144 }}></div>
    </div>
  );
}

export const Vacancy = withLocale(VacancyComponent);
