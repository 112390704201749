import React, { useEffect, useState, memo } from "react";

import { Locale } from "./contexts/Locale";
import { Header } from "./components/Header";
import { NavBar } from "./components/NavBar";
import { Digits } from "./components/Digits";
import { DigitsMobile } from "./components/DigitsMobile";
import { Services } from "./components/Services";
import { Technologies } from "./components/Technologies";
import { Cases } from "./components/Cases";
import { About } from "./components/About";
import { Vacancy } from "./components/Vacancy";
import { YourProject } from "./components/YourProject";
// import { Blog } from "./components/Blog";
import { Footer } from "./components/Footer";
import { ModalINeed } from "./components/Modals/ModalINeed";
import { ModalMainMenu } from "./components/Modals/ModalMainMenu";
import { ModalConfirmation } from "./components/Modals/ModalConfirmation";
import { ModalError } from "./components/Modals/ModalError";
import background from "./images/background.png";

const MemoHeader = memo(Header);
// const MemoNavBar = memo(NavBar);

function App() {
  const [isOpenModalINeed, setIsOpenINeed] = useState(false);
  const [isOpenModalMainMenu, setIsOpenMenu] = useState(false);
  const [isOpenModalConfirmation, setIsOpenModalConfirmation] = useState(false);
  const [isOpenModalError, setIsOpenModalError] = useState(false);
  const [pageload, setPageload] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function perfObserver(list, observer) {
      list.getEntries().forEach((entry) => {
        if (entry.duration > 0) {
          console.log(`${entry.name}'s duration: ${entry.duration}`);
        }
      });
    }
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    //eslint-disable-next-line
    const observer = new PerformanceObserver(perfObserver);
    observer.observe({
      entryTypes: ["measure", "mark", "resource", "navigation"],
    });
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const perfObserver = new PerformanceObserver((observedEntries) => {
      const entry = observedEntries.getEntriesByType("navigation")[0];
      setPageload(entry.duration);
    });
    perfObserver.observe({
      type: "navigation",
      buffered: true,
    });
  }, []);

  return (
    <div className="App" style={
      windowWidth > 1000 ?
        { backgroundImage: `url(${background})` } : { backgroundImage: "none" }
    }>
      <Locale>
        <NavBar
          setIsOpenINeed={setIsOpenINeed}
          setIsOpenMenu={setIsOpenMenu}
          pageload={pageload}
          setTrigger={setTrigger}
        />
        <main className="main_container">
          <MemoHeader trigger={trigger} setTrigger={setTrigger} />
          {windowWidth > 1000 ? (<Digits />) : (<DigitsMobile />)}
          <Services />
          <Cases />
          <Technologies />
          <About />
          <Vacancy />
          <YourProject />
          {/* <Blog setIsOpenINeed={setIsOpenINeed} /> */}
            <Footer />
        </main>
        {isOpenModalINeed && (
          <ModalINeed
            setIsOpenINeed={setIsOpenINeed}
            setIsOpenModalConfirmation={setIsOpenModalConfirmation}
            setIsOpenModalError={setIsOpenModalError}
          />
        )}
        {isOpenModalMainMenu && (
            <ModalMainMenu
                setIsOpenMenu={setIsOpenMenu}
            />
        )}
        {isOpenModalConfirmation && (
          <ModalConfirmation
            setIsOpenModalConfirmation={setIsOpenModalConfirmation}
          />
        )}
        {isOpenModalError && (
            <ModalError
                setIsOpenModalError={setIsOpenModalError}
            />
        )}
      </Locale>
    </div>
  );
}

export default App;
