import { withLocale } from "../../contexts/locale.context";
import styles from "../../css/ModalMobile.module.css";
import logo from "../../images/logo-vc-white.svg";
import Close from "../../images/skip.svg";
import background from "../../images/modal-mobile-background.png";
import Back from "../../images/back.svg";
import Button from "../UI/Button";
import logoMincifra from "../../images/logo-MincifryRF.svg";
import { Link } from "react-scroll";

const ModalMainMenuComponent = ({setIsOpenMenu, lang}) => {

  const closeModal = () => {
    document.getAnimations().forEach((animation) => {
      animation.playbackRate = 1;
    });
    // document.body.style.overflow = "scroll";
    setIsOpenMenu(false);
  };

  return (
    <>
      <div className={styles.blurBG} onClick={closeModal} />
      <div className={styles.centered}>
          <div className={styles.modalMainMenu} style={{backgroundImage: `url(${background})`}}>
              <ul className={styles.modalHeader}>
                  <li>
                      <img
                          src={Back}
                          alt="back"
                      />
                  </li>
                  <li>
                      <Link to="main" smooth={true} duration={2000}>
                          <img
                              src={logo}
                              className="logo"
                              alt="vercloud logo"
                          />
                      </Link>
                  </li>
                  <li onClick={closeModal}>
                      <img
                          src={Close}
                          alt="close"
                      />
                  </li>
              </ul>
              <ul className={styles.mainMenu}>
                  <li>
                      <Link to="about" smooth={true} duration={2000} onClick={closeModal}>
                         {lang.handle("menuAbout")}
                      </Link>
                  </li>
                  <li>
                      <Link to="services" smooth={true} duration={2000} onClick={closeModal}>
                        {lang.handle("menuServices")}
                      </Link>
                  </li>
                  <li>
                      <Link to="cases" smooth={true} duration={2000} onClick={closeModal}>
                        {lang.handle("menuCases")}
                      </Link>
                  </li>
                  <li>
                      <Link to="vacancy" smooth={true} duration={2000} onClick={closeModal}>
                        {lang.handle("menuVacancy")}
                      </Link>
                  </li>
                  <li>
                      {lang.handle("menuContacts")}
                  </li>
              </ul>
              <Button animated>
                  {lang.handle("btnfillBrief")}
              </Button>
              <div className={styles.mincifra}>
                  <img src={logoMincifra} className={styles.mincifra_logo} alt="logo-Mincifra"/>
                  <div>
                      <span className={styles.mincifra_text}>{lang.handle("footerIT")}</span>
                  </div>
              </div>
          </div>
      </div>
    </>
  );
};

export const ModalMainMenu = withLocale(ModalMainMenuComponent);
